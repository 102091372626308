import React from 'react';
import { Card, Container, CardBody } from 'reactstrap';
import { Col, Row } from 'reactstrap';
// import Panel from '../../../../../shared/components/Panel';

export default ({ data }) => (
  <Container className="todo-app">
    <Row>
      <Col md={12} style={{padding: 0}}>
        <h3 className="page-title">Réponses au questionnaire du commerçant</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} style={{ padding: 0 }}>
        {
          data.map((item, index) => {
            return (
              <div xs={12} key={`question-${(index + 1)}`} className="todo__item">
                <Card>
                  <CardBody className="todo__item">
                    {/* <label htmlFor={title} className="todo__label-checkbox">
                      <input
                        id={title}
                        type="checkbox"
                        className="todo__complete-toggle"
                        defaultChecked={completed}
                        required
                        onClick={this.handleComplete.bind(this)}
                      />
                      <span className="checkbox-indicator" />
                    </label> */}
                    <div className="todo__info">
                      <div className="todo__header" style={{display: 'block'}}>
                        {/* <h3>{item.question.value}</h3> */}
                        <h3 dangerouslySetInnerHTML={{ __html: item.question.value }}></h3>
                        {
                          item.question.help && (
                            <em style={{ fontSize:'12px' }}>{item.question.help}</em>
                          )
                        }
                        {/* <div className="todo__additional">
                          <p className="todo__due-date">Due date: 22.05.19</p>
                          <span className="todo__priority">Priority:</span>
                          <span className={priorityColorsClass} />
                        </div> */}
                      </div>
                      <div className="todo__content">
                        <div className="todo__description"><strong>Réponse:</strong> "{item.answer.value}"</div>
                        {/* <button className="todo__delete-btn" type="button" onClick={this.handleDelete.bind(this)}>
                          <DeleteForeverIcon />
                        </button> */}
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </div>
            )
          })
        }
      </Col>
    </Row>
  </Container>
);
