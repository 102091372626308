import React from 'react';
import Panel from '../../../../shared/components/Panel';
import { Row, Col } from 'reactstrap';

const Sales = ({ sellin, sellout, orderaverage }) => (
  <Panel md={12} lg={8} xl={8} sm={12} xs={12} title="Ventes">

    <div className="dashboard__sales-report">
      <Row>
        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{sellin}</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Sell-in</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{sellout}</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Sell-out</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{orderaverage} produit{orderaverage > 1 && 's'}</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Commande moyenne / commerce</h5>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Panel>
);

export default Sales;
