import React from 'react';
import Panel from '../../../../../shared/components/Panel';

const Survey = ({ satisfaction, salesImpact, imageImpact }) => (
  <Panel md={12} lg={12} xl={12} sm={12} xs={12} title="Satisfaction">
    {/* <div className="dashboard__sales-report"> */}
    <div>
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">Satisfaction</p>
        <p className="dashboard__sales-report-now">{satisfaction}/10</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">
          Impact positif sur les ventes
        </p>
        <p className="dashboard__sales-report-now">{salesImpact}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">
          Impact positif sur l'image
        </p>
        <p className="dashboard__sales-report-now">
          {!imageImpact && 'N/C'}
        </p>
      </div>
    </div>
  </Panel>
);

export default Survey;
