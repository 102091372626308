import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';

const Documents = ({ campaign, campaignid, campaignSurveyAccess}) => (
  <Panel md={12} lg={4} xl={4} sm={12} xs={12} title="Documents">
    {console.log(campaign)}
    <ul className="list list--icon list--icon-green">
      <li>
        <p>
          {
            (campaign.type === 'TRADE' && typeof campaign.options[1] !== 'undefined' && campaign.edcLink) ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <Link to={`/edc/visualisation/${campaignid}`} style={{ color: '#646777' }}>
                  Mon engagement de commande
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {
                  campaign.edc === 1 && (
                    <React.Fragment>
                      {
                        !campaign.campaignBrief ? (
                          <React.Fragment>
                            <span className="lnr lnr-checkmark-circle" style={{ color: '#F6dA6E' }} />
                            <Link to={`/edc/visualisation/${campaignid}`} style={{ color: '#646777' }}>
                              Je valide mon engagement de commande
                            </Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className="lnr lnr-checkmark-circle" style={{color: '#646777'}} />
                            Mon engagement de commande
                          </React.Fragment>
                        )
                      }
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )
          }
        </p>
      </li>

      <li>
        <p>
          {
            (campaign.optionSondage && campaign.sondageCompleted) ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <Link to={`/survey/questions/${campaignid}`} style={{ color: '#646777' }}>
                  Mon questionnaire commerçant
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {
                  !campaign.campaignBrief ? (
                    <React.Fragment>
                      <span className="lnr lnr-checkmark-circle" style={{ color: '#F6dA6E' }} />
                      <Link to={`/survey/creation/${campaignid}`} style={{ color: '#646777' }}>
                        Je complète le questionnaire
                      </Link>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="lnr lnr-checkmark-circle" style={{ color: '#646777' }} />
                      Mon questionnaire commerçant
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )
          }
        </p>
      </li>

      <li>
        <p>
          {
            (campaign.optionSondage && campaign.sondageCompleted && campaign.completed) ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <Link to={`/survey/results/${campaignid}`} style={{ color: '#646777' }}>
                  Résultats du questionnaire
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" style={{ color: '#646777' }} />
                Résultats du questionnaire
              </React.Fragment>
            )
          }
        </p>
      </li>

      <li>
        <p>
          {
            campaign.visuel ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <a 
                  href={campaign.visuel}
                  target="_blank"
                  style={{ color: '#646777' }}
                >
                  Mon visuel de campagne
                </a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" style={{ color: '#646777' }} />
                Mon visuel de campagne
              </React.Fragment>
            )
          }
        </p>
      </li>

      <li>
        <p>
          {
            (campaign.type === 'TRADE' && campaign.status >= 4 && campaign.shopsAcceptation) ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <Link to={`/edc/results/${campaignid}`} style={{ color: '#646777' }}>
                  Engagements de commande
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" style={{ color: '#646777' }} />
                Engagement de commande
              </React.Fragment>
            )
          }
        </p>
      </li>

      <li>
        <p>
          {
            campaign.rapport ? (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" />
                <a 
                  href={campaign.rapport}
                  target="_blank"
                  style={{ color: '#646777' }}
                >
                  Rapport de campagne PDF
                </a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="lnr lnr-checkmark-circle" style={{ color: '#646777' }} />
                Rapport de campagne PDF
              </React.Fragment>
            )
          }
        </p>
      </li>
      {
        (!campaign.sondageCompleted && campaign.status === 11) && (
          <li>
            <p style={{ textAlign: 'center', paddingTop: 20 }}>
              <Button color="primary" outline size="sm" onClick={campaignSurveyAccess}>
                Je complète le questionnaire
              </Button>
            </p>
          </li>
        )
      }
    </ul>
  </Panel>
);

export default Documents;
